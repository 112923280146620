<template>
  <div>
    <v-container fluid>

      <v-overlay :value="isLoading" color="white" opacity="1">
        <v-progress-circular
            color="gifty"
            indeterminate
            size="50"
        ></v-progress-circular>
      </v-overlay>

      <v-row v-if="error" justify="center">
        <v-col cols="12" lg="8">
          <v-alert dark type="error">
            Assurez-vous que vous avez correctement saisi l'URL de la page que vous essayez d'accéder. Vérifiez
            également votre connexion Internet pour vous assurer que vous êtes connecté
          </v-alert>
        </v-col>
      </v-row>

      <v-row v-else justify="center">
        <v-col cols="12" lg="8">

          <v-alert border="top" color="gifty" dark>
            <div class="d-flex justify-space-between align-center">
              <v-avatar color="gifty darken-1" tile>
                <v-img :src="require('@/assets/logo.svg')" class="ma-2" contain></v-img>
              </v-avatar>
              <span class="ml-2 f-17 font-weight-medium">
             {{ user.name }}
            </span>
              <v-spacer></v-spacer>
              <div class="f-15">
                <v-icon dense>mdi-phone</v-icon>
                {{ user.phone }}
              </div>
            </div>
          </v-alert>

          <v-card class="shadow">

            <v-card-title>

              <div class="f-17">
                <v-icon color="gifty">mdi-chart-box-outline</v-icon>
                Mes statistiques
              </div>

            </v-card-title>

            <v-divider/>

            <v-card-text>

              <v-row>
                <v-col cols="12">
                  <v-card color="gifty" dark flat>
                    <v-card-title class="pb-0 f-17">
                      {{ CurrencyFormatting(total_remaining_amount) }} Pts
                      <v-spacer/>
                      <v-avatar color="primary" rounded size="35">
                        <v-icon dark>mdi-currency-usd</v-icon>
                      </v-avatar>
                    </v-card-title>
                    <v-card-text>
                      Total
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row>

                <v-col v-for="(service, i) in services" :key="i" cols="12">
                  <v-card outlined>

                    <v-card-title class="pb-0 f-17 gifty--text">
                      {{ CurrencyFormatting(service.remaining_amount )}} Pts
                      <v-spacer/>
                      <v-avatar :color="service.background_color" rounded size="35">
                        <v-img :src="getImageLink(service.image)" contain></v-img>
                      </v-avatar>
                    </v-card-title>
                    <v-card-text>
                      {{ service.name }}
                    </v-card-text>

                  </v-card>
                </v-col>

              </v-row>


              <v-card class="mt-5" outlined>

                <v-card-title class="f-17">
                  Historiques de recouvrement
                </v-card-title>

                <v-divider/>

                <v-card-text class="pa-0">

                  <v-data-table :headers="headers"
                                :items="recoveries"
                  >

                    <template v-slot:item.service="{ item }">
                      <div class="d-flex align-center">
                        <v-avatar :color="item.service.background_color" rounded size="35">
                          <v-img :src="getImageLink(item.service.image)" contain></v-img>
                        </v-avatar>
                        <div class="ml-2">{{ item.service.name }}</div>
                      </div>
                    </template>

                    <template v-slot:item.recovery_at="{ item }">
                      <div class="d-flex align-center">
                        {{ dateFormat(item.recovery_at) }}
                      </div>
                    </template>

                    <template v-slot:item.old_amount="{ item }">
                      <div class="font-weight-medium gifty--text">
                        {{ CurrencyFormatting(item.old_amount)}} Pts
                      </div>
                    </template>


                    <template v-slot:item.paid_amount="{ item }">
                      <div class="font-weight-medium primary--text">
                        {{ CurrencyFormatting(item.paid_amount) }} Pts
                      </div>
                    </template>

                  </v-data-table>
                </v-card-text>
              </v-card>


            </v-card-text>


          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      error: false,
      total_remaining_amount: 0,
      recoveries: [],
      services: [],
      isLoading: false,
      headers: [
        {sortable: false, text: 'Service', value: 'service'},
        {sortable: false, text: 'Ancien montant', value: 'old_amount'},
        {sortable: false, text: 'Montant payé', value: 'paid_amount'},
        {sortable: false, text: 'Date de recouvrement', value: 'recovery_at'},
      ],

      service_id: '',
      user: {},
    }
  },
  methods: {
    getRecoveries() {
      this.isLoading = true
      axios.get(process.env.VUE_APP_BASE_URL + "salepoint-statistics", {
        params: {
          service_id: this.service_id,
          key: this.$route.query.key,
        }
      }).then(res => {
        this.total_remaining_amount = res.data.data.total_remaining_amount
        this.recoveries = res.data.data.recoveries
        this.services = res.data.data.services
        this.user = res.data.data.user
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        this.error = true
        console.log(err)
      })
    },
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY, HH:mm');
    }
  },
  created() {
    if (!this.$route.query.key) {
      this.error = true
    } else {
      this.getRecoveries()
    }
  }
}
</script>

<style>
.row {
  margin-bottom: 0;
}
</style>